.btn{
	height: 40px;
	font-size: 16px;
	vertical-align: middle;
	font-family: $roboto;
	cursor: pointer;
  	margin: 10px auto;
  	max-width: 100%;
	position: relative;
	&.btn-secundary{
		background-color: #F39734;
		color:$white;
		margin: 10px auto;
		padding-top: 8px;
		width: 100%;
   		&:disabled{
			background: $light-gray;
			color:$txt-gray;
			opacity: 1;
		}
		.arrow{
			border: solid $white;
			border-width: 0 2px 2px 0;
			position: absolute;
			right: 40px;
			top: 18px;
			padding: 4px;
		}
  	}
	&.round{
		border-radius: 18px;
	}
	&.btn-outline{
		border-color: $main-color;
		color: $main-color;
		&-light{
		.arrow{
			top: 22px;
		}
    }
    .arrow{
		border: solid $main-color;
		border-width: 0 2px 2px 0;
		position: absolute;
		right: 17px;
		top: 15px;
		padding: 4px;
    }
  }
  &.btn-outline-w{
    border-color: $white;
    color: $white;
    padding-top: 10px;
    &:hover{
      background-color: $white;
      color:$main-color;
      .arrow{
        border: solid $main-color;
        border-width: 0 2px 2px 0;
      }
    }
    .arrow{
      border: solid $white;
      border-width: 0 2px 2px 0;
      position: absolute;
      right: 17px;
      top: 17px;
      padding: 4px;
    }
  }
  .icon{
    display: inline-block;
    width: 10%;
  }
}
.btn-blue{
  color: $white;
  background-color: $blue;
}
.btn-dtc{
  color: $white;
  background-color: $midc;
  .arrow{
    border: solid $white;
    border-width: 0 2px 2px  0;
    position: absolute;
    right: 17px;
    top: 21px;
    padding: 4px;
  }
}
.btn-lg{
	width: 300px;
	height: 50px;
	font-weight: bold;
	text-align: center;
}
.arrow{
  border: solid $main-color;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.link{
  color: $main-color;
  font-size: 14px;
}
.link-b {
  color:$blue;
}

.btn-pda {
  display: flex;  
  padding: 10px 0;
  border-radius: 8px;
  text-align: center;  
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  @media(max-width:767px){
      margin: 8px auto 8px;
  }
  &.margin-l {
    margin: 16px 0 24px;
    @media(max-width:767px){
      margin: 16px auto 24px;
    }
  }
  &.margin-r {
    margin: 16px 0 24px;
    @media(max-width:767px){
      margin: 16px auto 24px;
    }
  }
  &.margin-auto {
    margin: 16px auto 24px;
    @media(max-width:767px){

    }
  }
  &.h-lg-btn {
    height: 52px;
    @media(max-width:767px){
      height: 46px;
    }
  }
  &.h-md-btn {
    height: 46px;
    @media(max-width:767px){
      height: 42px;
    }
  }
  &.w-40-btn {
    width: 40%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-45-btn {
    width: 45%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-60-btn {
    width: 60%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-100-btn {
    width: 100%;
  }    
  &.btn-orange{
    background-color: $terciary-color;
    color:$white;
    &:hover {
      background-color: $terciary-color2;
    }
    span {
        font-family: $roboto;
        font-size: 15px;
        display: inline-block;
        margin: 0 8px;
        @media(max-width:767px){
          font-size: 16px;
        }
    }
    i {            
        font-size: 13px;
        color: $white;
        display: inline-block;
        margin-left: 16px;
    }
  }
  &.btn-outline-white{
    border: 1px #fff solid;
    color:$white;   
    background-color: transparent; 
    &:hover {
      background-color: $white;
      color: $main-color;
    }
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 13px;
      display: inline-block;
    }
  }
  &.btn-outline-pink{
    border: 1px $main-color solid;
    color:$main-color;
    background-color: $white;
    &:hover {
      background-color: $main-color;
      color: $white;
    }
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 13px;
      display: inline-block;
    }
  }
  &.btn-pink{
    background-color: $main-color;
    color:$white;
    &:hover {
      background-color: $secondary-color;
      color:$white;
    }
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 13px;
      display: inline-block;
    }
  }
  &.btn-link{
    color: $main-color;
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 13px;
      display: inline-block;
    }
  }
}