@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url("https://use.typekit.net/mre5lng.css");
$roboto: 'Roboto', sans-serif;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$din: din-2014,sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');
$cedarville: 'Cedarville Cursive', cursive;