$main-color:#B12384;
$secondary-color:#750050;
$terciary-color: #F39734;
$terciary-color2: #fa993a;
$recommended: #FFAB31;
$light-main:#EFD3E6;
$midc: #632678;
$blue:#08478E;
$mid-blue:#0D6EB6;
$light-blue:#EBF0F6;
$light-blue-2:#90D3FF;
$light-blue-3: #DEEBF9;
$clear-blue: #E9F2F8;
$bg-alert: #D7EBF8;
$bg-alert-dark: #4DB8FF;
$bg-main:#F5F8FB;
$midc:#632678;
$white:#ffffff;
$black:#000000;
$aprove: #3F9B53;
$check:#00CC99;
$error:#F24343;
$purple-dark: #443462;
$castigo: #632678;
// $neg:
$bg-dark: hsla(219, 3%, 34%, 1);
$txt-gray:#767579;
$txt-gray-2: #606060;
$txt-gray-3: #6F6F6F;
$txt-gray-4: #666666;
$txt-alert: #0077B2;
$mid-gray:#71716E;
$mid-gray-2: #B4B4B4;
$mid-gray-3: #AFAFAF;
$gray: #999999;
$dark-gray: #54565A;
$light-gray:#CECECE;
$light-gray-2: #BABABA;
$light-gray-3:#F1F1F7;
$light-gray-4: #F1F1F7;
$light-gray-5: #DFDFDF;
$light-gray-6:#EEEEEE;
$super-light-gray:#F2F2F2;
//$error:hsla(318, 66%, 40%, 1);
$form-gray:hsla(0, 0%, 50%, 1);
$form-placeholder:hsla(0, 0%, 69%, 1);
$form-input-border: hsla(210, 14%, 83%, 1);
$bg-rtd:#B5D444;